import React, { useState, useContext } from "react";
import { Card, CardBody, CardTitle, Form, FormGroup, Input, Label, Alert } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { FaEnvelope } from 'react-icons/fa';
import { ApiComunicator } from "../../Helpers/ApiComunicator.js";
import { Spinner, CustomButton } from "../../Components/index.js";
import { LanguageContext } from "../../App";
import './RecuperarPassword.css';

const RecuperarPassword = () => {
    const { diccionario } = useContext(LanguageContext);
    const navigate = useNavigate();

    const [recuperarForm, setRecuperarForm] = useState({ email: "" });
    const [loading, setLoading] = useState(false);
    const [alerta, setAlerta] = useState({ mensaje: '', show: false, color: null });
    const [deshabilitarEnviar, setDeshabilitarEnviar] = useState(false);

    async function handleSubmit(e) {
        e.preventDefault();
        setLoading(true);
        try {
            await ApiComunicator("/auth/recuperarPassword", 'POST', { email: recuperarForm.email });
            setDeshabilitarEnviar(true);
            setAlerta({
                mensaje: diccionario.mensajeExitoRecuperacion,
                show: true,
                color: "success"
            });
        } catch (error) {
            console.log(error);
            setAlerta({
                show: true,
                mensaje: error.mensaje,
                color: "danger"
            });
        } finally {
            setLoading(false);
        }
    }

    const handleVolver = () => {
        navigate("/login");
    };

    return (
        <div id="cambiarPw">
            {loading && <Spinner />}
            <Card>
                <CardTitle id="titulo-pw" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <img src={process.env.PUBLIC_URL + '/arauco.svg'} alt="arauco" />
                    <h2 style={{ textAlign: 'center' }}>{diccionario.tituloLogin}</h2>
                </CardTitle>
                <CardBody>
                    {alerta.show && (
                        <Alert color={alerta.color}>
                            {alerta.mensaje}
                        </Alert>
                    )}
                    <Form onSubmit={handleSubmit}>
                        <FormGroup>
                            <div className="text-center">
                                <b>{diccionario.recuperarPassword}</b>
                            </div>
                        </FormGroup>
                        <FormGroup>
                            <div>
                                <p>{diccionario.instruccionesRecuperacion}</p>
                            </div>
                        </FormGroup>
                        <FormGroup >
                            <Label for="mail" className="mt-3"> {diccionario.email}</Label>
                            <Input
                                type="email"
                                name="email"
                                onChange={e => setRecuperarForm({ email: e.target.value })}
                                value={recuperarForm.email}
                                required
                            />
                        </FormGroup>
                        <div className="d-flex justify-content-between mt-5">
                            <CustomButton size="md" colorFondo="beige" onClick={handleVolver}>
                                {diccionario.volver}
                            </CustomButton>
                            <CustomButton type="submit" size="md" colorFondo="orange" disabled={deshabilitarEnviar}>
                                {diccionario.enviar}
                            </CustomButton>
                        </div>
                    </Form>
                </CardBody>
            </Card>
        </div>
    );
};

export default RecuperarPassword;