import React from 'react';
import { Navigate } from 'react-router-dom'
import { FaHouseUser, FaCubes, FaLock, FaList } from 'react-icons/fa';

const Home = React.lazy(() => import('./Pages/Home/Home.js'));
const ListadoEstructuras = React.lazy(() => import('./Pages/Estructuras/ListadoEstructuras.js'))
const Estructuras = React.lazy(() => import('./Pages/Estructuras/Estructuras.js'))
const AltaEstructura = React.lazy(() => import('./Pages/Estructuras/AltaEstructura.js'))
const EditarEstructura = React.lazy(() => import('./Pages/Estructuras/EditarEstructura.js'))
const AltaItem = React.lazy(() => import('./Pages/Estructuras/AltaItem.js'))
const EditarItem = React.lazy(() => import('./Pages/Estructuras/EditarItem.js'))
const VerEstructura = React.lazy(() => import('./Pages/Estructuras/VerEstructura.js'))
const Bloqueos = React.lazy(() => import('./Pages/Bloqueos/Bloqueos.js'))
const VerBloqueo = React.lazy(()=> import('./Pages/Bloqueos/VerBloqueo.js'))
const Informes = React.lazy(() => import('./Pages/Informes/Informes.js'))
const VistaInformes = React.lazy(()=>import('./Pages/Informes/VistaInformes.js'))
const ListadoBloqueos = React.lazy(()=>import('./Pages/Bloqueos/ListadoBloqueos.js'))
const CrearSolicitudBloqueo = React.lazy(()=>import('./Pages/Bloqueos/CrearSolicitudBloqueo.js'))

export const routes = {
    default: {
        path: _ => "",
        component: <Navigate to="bloqueos" replace />,
        parent: null,
        pathPattern: "/",
        breadcrumb: _ => ""
    },

    /* home: {
        path: _ => "home",
        component: <Home />,
        parent: null,
        icon: <FaHouseUser />,
        name: diccionario => diccionario.inicio,
        pathPattern: "/home",
        breadcrumb: diccionario => diccionario.home
    }, */

    bloqueos: {
        path: _ => 'bloqueos',
        component: <Bloqueos />,
        parent: null,
        icon: <FaLock />,
        name: diccionario => diccionario.bloqueos,
        pathPattern: '/bloqueos',
        breadcrumb: diccionario => diccionario.bloqueos
    },

    listadoBloqueos : {
        path:_ => 'listado-bloqueos',
        component : <ListadoBloqueos/>,
        parent: "bloqueos",
        pathPattern: '/bloqueos/listado-bloqueos',
        breadcrumb: diccionario => diccionario.listadoBloqueos
    },
    verBloqueo : {
        path:_ => 'ver-bloqueo',
        component : <VerBloqueo/>,
        parent: "bloqueos",
        pathPattern: '/bloqueos/ver-bloqueo',
        breadcrumb: diccionario => diccionario.verBloqueo
    },

    nuevaSoliBloqueo : {
        path:_ => 'nuevo-bloqueo',
        component : <CrearSolicitudBloqueo/>,
        parent: "bloqueos",
        pathPattern: '/bloqueos/nuevo-bloqueo',
        breadcrumb: diccionario => diccionario.nuevoBloqueo
    },

    estructuras: {
        path: _ => 'estructuras',
        component: <Estructuras />,
        parent: null,
        icon: <FaCubes />,
        name: diccionario => diccionario.estructuras,
        pathPattern: '/estructuras',
        breadcrumb: diccionario => diccionario.estructuras
    },

    listadoEstructuras: {
        path: _ => 'listado-estructuras',
        component: <ListadoEstructuras />,
        parent: "estructuras",
        pathPattern: '/estructuras/listado-estructuras',
        breadcrumb: diccionario => diccionario.listadoEstructuras
    },

    nuevaEstructura: {
        path: _ => 'nueva-estructura',
        component: <AltaEstructura />,
        parent: "estructuras",
        pathPattern: '/estructuras/nueva-estructura',
        breadcrumb: diccionario => diccionario.nuevaEstructura
    },

    editarEstructura: {
        path: _ => 'editar-estructura',
        component: <EditarEstructura />,
        parent: "estructuras",
        pathPattern: '/estructuras/editar-estructura',
        breadcrumb: diccionario => diccionario.editarEstructura
    },

    verEstructura: {
        path: _ => 'ver-estructura',
        component: <VerEstructura />,
        parent: "estructuras",
        pathPattern: '/estructuras/ver-estructura',
        breadcrumb: diccionario => diccionario.verEstructura
    },

    nuevoItem: {
        path: _ => 'nuevo-item',
        component: <AltaItem />,
        parent: "verEstructura",
        pathPattern: '/estructuras/ver-estructura/nuevo-item',
        breadcrumb: diccionario => diccionario.nuevoItem
    },

    editarItem: {
        path: _ => 'editar-item',
        component: <EditarItem />,
        parent: "verEstructura",
        pathPattern: '/estructuras/ver-estructura/editar-item',
        breadcrumb: diccionario => diccionario.actualizarItem
    },
    /* informes: {
        path: _ => 'informes',
        component: <Informes />,
        parent: null,
        icon: <FaList />,
        name: diccionario => diccionario.informes,
        pathPattern: '/informes',
        breadcrumb: diccionario => diccionario.informes
    }, */
    vistaInformes:{
        path:_=> 'vista-informes',
        component: <VistaInformes/>,
        parent: 'informes',
        pathPattern: '/informes/vista-informes',
        breadcrumb: diccionario => diccionario.vistaInformes
    }
}