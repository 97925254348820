import './Spinner.css';
import { Spinner as SpinnerBootstrap } from 'reactstrap';

export const Spinner = ({ message }) => {
    return (
        <div id="custom-spinner">
            <div className="spinner-wrapper">
                <SpinnerBootstrap className="custom-spinner" />
                <p>{message}</p>
            </div>
        </div>
    )
}