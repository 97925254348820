import React, { useState, useEffect } from 'react';
import { FaChevronLeft, FaChevronRight, FaAngleDoubleLeft, FaAngleDoubleRight } from 'react-icons/fa';
import './Pagination.css';

export const CustomPagination = (props) => {
  const [current, setCurrent] = useState(props.currentPage || 1);

  let pages = [...Array(props?.pages).keys(), props.pages];
  pages.shift();

  function onSelection(page) {
    if (!pages.includes(page) || page == current) {
      return;
    }
    setCurrent(page);
    if (props.onSelection) {
      props.onSelection(page);
    }
  }

  const PagesToRender = () => {
    let prevPages = pages.slice(Math.max(current - 4, 0), current).filter((p) => pages.includes(p));
    let nextPages = current < 5 ? pages.slice(current, current + 5) : pages.slice(current, current + 2);
    nextPages = nextPages.filter((p) => pages.includes(p));
    if (current < 5 && current > 0 && pages.length > 5) {
      for (let i = 0; i < current - 1; i++) {
        nextPages.pop();
      }
    }
    if (prevPages.length == 0 && nextPages.length == 0) {
      prevPages.push(1);
    }
    return [...prevPages, ...nextPages].map((btn) => (
      <li key={btn} className={`pagination-item ${btn === current ? 'active' : ''}`}>
        <button
          onClick={() => onSelection(btn)}
          className="pagination-link"
        >
          {btn}
        </button>
      </li>
    ));
  };

  useEffect(() => {
    setCurrent(props.currentPage);
  }, [props.currentPage]);

  return (
    <>
      {current && (
        <nav>
          <ul className="custom-pagination">
            <li className="pagination-item">
              <button
                onClick={() => onSelection(1)}
                className="pagination-link"
              >
                <FaAngleDoubleLeft />
              </button>
            </li>
            <li className="pagination-item">
              <button
                onClick={() => onSelection(current - 1)}
                className="pagination-link"
              >
                <FaChevronLeft />
              </button>
            </li>
            <PagesToRender />
            <li className="pagination-item">
              <button
                onClick={() => onSelection(current + 1)}
                className="pagination-link"
              >
                <FaChevronRight />
              </button>
            </li>
            <li className="pagination-item">
              <button
                onClick={() => onSelection(pages?.at(-1))}
                className="pagination-link"
              >
                <FaAngleDoubleRight />
              </button>
            </li>
          </ul>
        </nav>
      )}
    </>
  );
};