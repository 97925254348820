import React, { useEffect, useState, createContext } from 'react';
import { Layout, Spinner } from './Components';
import { Routes, Route, useLocation, Outlet, useNavigate } from 'react-router-dom';
import './App.scss';
import { routes } from './routes.js';
import { ApiComunicator } from './Helpers/ApiComunicator.js';
import RecuperarPassword from './Pages/Login/RecuperarPassword.js';
import CambiarPassword from './Pages/Login/CambiarPassword.js';
import { NotFound } from './Components/NotFound/NotFound.js';
import espanol from './Idiomas/diccionario-es.json'
import ingles from './Idiomas/diccionario-en.json'

export const AuthContext = createContext();
export const LanguageContext = createContext();

function App() {
  const [autenticado, setAutenticado] = useState(false);
  const [idioma, setIdioma] = useState(() => {
    return localStorage.getItem('idioma') || 'es';
  });
  const [diccionario, setDiccionario] = useState(() => {
    return idioma === 'es' ? espanol : ingles;
  });

  const Login = React.lazy(() => import('./Pages/Login/Login.js'));

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setDiccionario(idioma === 'es' ? espanol : ingles);
    localStorage.setItem('idioma', idioma);
  }, [idioma]);

  const languageContextValue = {
    idioma,
    setIdioma: (newIdioma) => {
      setIdioma(newIdioma);
      localStorage.setItem('idioma', newIdioma);
    },
    diccionario
  };

  function RutasPrivadas() {
    let arrayRutas = Object.keys(routes).map(r => { return { ...routes[r], name: r } });

    const renderRutas = (padre) => {
      let h = [];
      let rts = arrayRutas.filter(r => r.parent === padre);

      for (let i = 0; i < rts.length; i++) {
        let props = { path: rts[i].path() };

        if (rts[i].component) props.element = rts[i].component;

        let children = renderRutas(rts[i].name);

        if (children.length > 0) {
          h.push(
            <Route key={rts[i].name} {...props}>
              {rts[i].index && <Route index element={rts[i].index.component} />}
              {children}
              {rts[i].component && <Route index element={rts[i].component} />}
            </Route>
          );
        } else {
          h.push(
            <Route key={rts[i].name} {...props}>
              {rts[i].index && <Route index element={rts[i].index.component} />}
              {rts[i].component && <Route index element={rts[i].component} />}
            </Route>
          );
        }
      }
      return h;
    };

    return (
      <Route
        element={
          <React.Suspense fallback={<Spinner />}>
            <AuthContext.Provider value={{ setAutenticado }}>
              {autenticado ? <Layout /> : <Outlet />}
            </AuthContext.Provider>
          </React.Suspense>
        }
      >
        {renderRutas(null)}
      </Route>
    );
  }

  async function verificarToken() {
    try {
      await ApiComunicator('/auth/checkToken', "GET");
      setAutenticado(true);
      return;
    } catch (e) {
      let idioma = localStorage.getItem('idioma')
      localStorage.clear();
      localStorage.setItem('idioma', idioma)
      setAutenticado(false);

      if (location.pathname.startsWith("/new-password/")) {
        const pathTokens = location.pathname.split('/');
        const token = pathTokens[pathTokens.length - 2];
        if (location.pathname !== "/login" && location.pathname !== "/recuperar" && !location.pathname.startsWith("/new-password/") && !token) {
          navigate("/login", { replace: true });
        }
      } else {
        if (location.pathname !== "/login" && location.pathname !== "/recuperar") {
          navigate("/login", { replace: true });
        }
      }
      return;
    }
  }

  useEffect(() => {
    if (location.pathname === "/login" && autenticado) {
      navigate(-1);
    }

    verificarToken();
  }, [location]);

  return (
    <div className="App">
      <LanguageContext.Provider value={languageContextValue}>
        <Routes>
          <Route
            path="login"
            element={
              <React.Suspense fallback={<Spinner />}>
                <AuthContext.Provider value={{ setAutenticado }}>
                  <Login />
                </AuthContext.Provider>
              </React.Suspense>
            }
          />
          <Route path="recuperar" element={
            <React.Suspense fallback={<Spinner />}>
              <RecuperarPassword />
            </React.Suspense>} />

          <Route path="new-password/:token" element={
            <React.Suspense fallback={<Spinner />}>
              <CambiarPassword />
            </React.Suspense>} />
          {RutasPrivadas()}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </LanguageContext.Provider>
    </div>
  );
}

export default App;
