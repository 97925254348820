import React, { useState, useEffect, useContext, useMemo } from 'react';
import { Outlet, useLocation, matchRoutes, useNavigate, Link } from 'react-router-dom';
import { Card, Nav, NavItem, NavLink, TabContent } from 'reactstrap';
import './Layout.css';
import { Footer } from '../Footer/Footer';
import { Spinner, CustomButton } from '../../Components';
import { ApiComunicator } from '../../Helpers/ApiComunicator';
import { routes } from '../../routes';
import { FaBars, FaTimes } from 'react-icons/fa';
import { PanelUsuario } from '../Header/PanelUsuario';
import { DropdownIdioma } from '../Header/DropdownIdioma';
import { LanguageContext, AuthContext } from '../../App';
import useWidth from '../../Hooks/useWidth';

export const Layout = () => {
    const [fullBreadcrumb, setFullBreadcrumb] = useState([]);
    const location = useLocation();
    const navigate = useNavigate();
    const width = useWidth();
    const { idioma, setIdioma, diccionario } = useContext(LanguageContext);
    const { setAutenticado } = useContext(AuthContext);

    const [allowedPatterns, setAllowedPatterns] = useState([]);
    const [rutasTab, setRutasTab] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentRoute, setCurrentRoute] = useState(null);
    const [collapsed, setCollapsed] = useState(true);
    const [vistaActual, setVistaActiva] = useState(null);

    const toggleNavbar = () => setCollapsed(!collapsed);

    const isActive = (path) => location.pathname.startsWith('/' + path);

    const logout = async () => {
        try {
            await ApiComunicator('/auth/logout', 'GET');
            setAutenticado(false);
            navigate('/login', { replace: true });
        } catch (e) {
            console.error(e);
        }
    };

    const pathPatterns = useMemo(() => Object.entries(routes).map(([key, route]) => ({
        path: route.pathPattern,
        breadcrumb: route.breadcrumb || route.name,
        parent: route.parent,
        icon: route.icon,
        name: route.name,
        key
    })), []);

    useEffect(() => {
        const idEstructura = location.state?.idEstructura || localStorage.getItem('idEstructura');
        if (idEstructura) {
            localStorage.setItem('idEstructura', idEstructura);
        }

        const matched = matchRoutes(pathPatterns, location);
        if (matched) {
            const current = matched[matched.length - 1].route;
            setCurrentRoute(current);

            const breadcrumbArray = [];
            let currentRouteKey = current.key;
            while (currentRouteKey) {
                const currentRoute = routes[currentRouteKey];
                breadcrumbArray.unshift({
                    name: currentRoute.breadcrumb ? currentRoute.breadcrumb(diccionario) : (currentRoute.name ? currentRoute.name(diccionario) : ''),
                    path: currentRoute.pathPattern,
                    key: currentRouteKey,
                    icon: currentRoute.icon
                });
                currentRouteKey = currentRoute.parent;
            }
            setFullBreadcrumb(breadcrumbArray);

            if (breadcrumbArray.length > 0) {
                setVistaActiva(
                    <>
                        {breadcrumbArray[0].icon} {breadcrumbArray[0].name}
                    </>
                );
            }
        }
    }, [location, diccionario, pathPatterns]);

    useEffect(() => {
        ApiComunicator("/permisos/getRutas", "GET")
            .then((res) => {
                const allowedRoutes = res.map(r => r.ruta);
                setAllowedPatterns(res.map(r => r.pattern));

                const topLevelRoutes = Object.entries(routes)
                    .filter(([key, route]) => route.parent == null && route.icon && allowedRoutes.includes(key))
                    .map(([key, route]) => ({ ...route, id: key }));

                setRutasTab(topLevelRoutes);
                setLoading(false);
            })
            .catch((err) => {
                console.error(diccionario.errorFetchingRoutes, err);
                setLoading(false);
            });
    }, [diccionario]);

    if (loading) {
        return <Spinner />;
    }

    const handleBreadcrumbClick = (crumb, index) => {
        if (crumb.key === 'verEstructura') {
            const idEstructura = localStorage.getItem('idEstructura');
            if (idEstructura) {
                navigate(crumb.path, { state: { idEstructura: idEstructura } });
            } else {
                console.error("No se envio estructura");
            }
        } else {
            navigate(crumb.path);
        }
    };

    const handleBackClick = () => {
        switch (location.pathname) {
          case '/estructuras/editar-estructura':
            navigate('/estructuras/ver-estructura', {
              state: { 
                activeTab: '1'
              }
            });
            break;
          
          case '/estructuras/ver-estructura':
            navigate('/estructuras');
            break;
          
          default:
            navigate(-1)
            break;
        }
      };

    const BackButton = () => {
        const routesWithoutBackButton = ['home', 'listadoEstructuras', 'listadoBloqueos', 'vistaInformes'];
        const currentRouteKey = fullBreadcrumb[fullBreadcrumb.length - 1]?.key;

        if (routesWithoutBackButton.includes(currentRouteKey)) {
            return null;
        }

        return (
            <div className={width > 500 ? "p-3" : "pt-3"}>
                <CustomButton
                    size="sm"
                    colorFondo="beige"
                    onClick={handleBackClick}
                >
                    {diccionario.back || 'Volver'}
                </CustomButton>
            </div>
        );
    };

    const renderBreadcrumbs = () => (
        <div className={`breadcrumb-container ${width <= 800 ? 'mobile-breadcrumb' : ''}`}>
            {fullBreadcrumb.map((crumb, index) => (
                <React.Fragment key={index}>
                    {index > 0 && <span className="breadcrumb-separator"> / </span>}
                    {index === fullBreadcrumb.length - 1 ? (
                        <strong>{crumb.name}</strong>
                    ) : (
                        <span
                            onClick={() => handleBreadcrumbClick(crumb, index)}
                            className="parent-route-link"
                        >
                            {crumb.name}
                        </span>
                    )}
                </React.Fragment>
            ))}
        </div>
    );

    const MobileMenu = () => (
        <div className="mobile-menu">
            {collapsed ? (
                <div className="mobile-menu-icon">
                    <FaBars size={28} onClick={toggleNavbar} />
                </div>
            ) : (
                <div className="fullscreen-menu">
                    <div className="menu-header">
                        <FaTimes size={28} onClick={toggleNavbar} />
                    </div>
                    <Nav navbar className="menu-content">
                        <NavItem className="mobile-user-panel">
                            {localStorage?.getItem('nombre')} {localStorage?.getItem('apellido')}
                        </NavItem>
                        <hr />
                        {renderNavItems()}
                        <hr />
                        <NavItem className="language-buttons">
                            <CustomButton onClick={() => setIdioma('es')}>
                                ES
                            </CustomButton>
                            <CustomButton onClick={() => setIdioma('en')}>
                                EN
                            </CustomButton>
                        </NavItem>
                        <hr />
                        <NavItem className="logout-button">
                            <CustomButton colorFondo="brownishGrey" onClick={logout}>
                                {diccionario.cerrarSesion}
                            </CustomButton>
                        </NavItem>
                    </Nav>
                </div>
            )}
        </div>
    );

    const UpdatedHeader = () => (
        <Nav id="app-header">
            <NavItem>
                <Link to="/" className="nav-link">
                    <img src={process.env.PUBLIC_URL + '/arauco.svg'} id="logo-arauco" alt="arauco" />
                </Link>
            </NavItem>
            <NavItem className="header-buttons">
                {width <= 800 ? (
                    <MobileMenu />
                ) : (
                    <>
                        <DropdownIdioma />
                        <PanelUsuario />
                    </>
                )}
            </NavItem>
        </Nav>
    );

    const renderNavItems = () => (
        rutasTab.map((r, index) => (
            <NavItem 
                key={`item-${index}`} 
                className={width > 800 ? "flex-shrink-0" : ""}
                style={{ width: width <= 800 ? '100%' : 'auto' }}
            >
                <NavLink
                    href={'/' + r.path()}
                    active={isActive(r.path())}
                    onClick={width <= 800 ? toggleNavbar : undefined}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        padding: '0.75rem 1rem',
                    }}
                >
                    <span style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginRight: width <= 800 ? '0.5rem': '0.15rem',
                        fontSize: '1em',
                        width: '1.2em',
                        height: '1em',
                    }}>
                        {r.icon}
                    </span>
                    <span style={{
                        display: 'flex',
                        alignItems: 'center',
                        whiteSpace: 'nowrap',
                        height: '1em',
                    }}>
                        {r.name(diccionario)}
                    </span>
                </NavLink>
            </NavItem>
        ))
    );

    return (
        <div className="layout-container">
            <UpdatedHeader />
            <div id={width > 800 ? "tabs-layout" : "navbar-layout"}>
                {width > 800 && (
                    <Nav tabs className="desktop-nav">
                        {renderNavItems()}
                        <NavItem className="breadcrumb-tab">
                            <NavLink tag="div">
                                {renderBreadcrumbs()}
                            </NavLink>
                        </NavItem>
                    </Nav>
                )}
                {width <= 800 && (
                    <div className="mobile-breadcrumb-container">
                        {renderBreadcrumbs()}
                    </div>
                )}
                <TabContent className="tab-content">
                    <Card className="main-card">
                        {allowedPatterns.includes(location.pathname) && (
                            <>
                                <Outlet />
                                <BackButton />
                            </>
                        )}
                    </Card>
                </TabContent>
            </div>
            <Footer show={width > 600} />
        </div>
    );
};