import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import React, { useContext } from "react";
import { CustomButton } from "../CustomButton/CustomButton";
import { LanguageContext } from '../../App';
import './CustomModal.css';

export const CustomModal = ({ isOpen, titulo, mensaje, size = "md", close, callback }) => {
    const { diccionario } = useContext(LanguageContext);
    
    return (
        <Modal
            size={size}
            isOpen={isOpen}
            centered={true}
            className="custom-modal"
        >
            <ModalHeader>
                {titulo}
            </ModalHeader>
            <ModalBody>
                {mensaje}
            </ModalBody>
            <ModalFooter className="d-flex justify-content-between">
                <CustomButton
                    size="sm"
                    onClick={close}
                    colorFondo="beige"
                >
                    {diccionario.cancelar}
                </CustomButton>
                <CustomButton
                    onClick={callback}
                    colorFondo="orange"
                    size="sm"
                >
                    {diccionario.aceptar}
                </CustomButton>
            </ModalFooter>
        </Modal>
    );
}