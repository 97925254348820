import Config from '../config.js';

export const ApiComunicator = (urlTail, method, info, signal, isADownload = false) => {
  return new Promise((resolve, reject) => {

    let url = Config.endpoint + urlTail;
    const params = {
      credentials: "include",
      method: method,
      mode: 'cors',
      headers: new Headers({
        'Content-Type': 'application/json;charset=utf-8'
      })
    };
    if (info) {
      params.body = JSON.stringify(info)
    }
    if (signal) {
      params.signal = signal
    }

    fetch(url, params)
      .then(response => {
        if (response.ok) {
          if (isADownload === true) {
            return response;
          } else {
            return response.json();
          }
        } else {
          //Se vencio el token / No autorizado
          if (response.status === 401) {
            //console.log("No está autorizado para realizar esta acción. Se recargará la página.");
            //window.location.reload();
            reject(response)
          } else {
            throw response;
          }
        }
      })
      .then(data => {
        resolve(data)
      })
      .catch(async (error) => {
        try {
          let errorJson = await error.json();
          reject(errorJson)
        }
        catch (e) {
          reject(error)
        }
      });
  })

}