import React, { useState, useEffect, useContext } from "react";
import { Card, CardBody, CardTitle, Form, FormGroup, Input, Label, Button, Alert } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { FaKey } from 'react-icons/fa';
import { ApiComunicator } from "../../Helpers/ApiComunicator";
import { CustomButton, Spinner } from "../../Components";
import './RecuperarPassword.css';
import { AuthContext, LanguageContext } from "../../App";

const CambiarPassword = () => {
    const navigate = useNavigate();
    const { diccionario } = useContext(LanguageContext);

    const [formPassword, setFormPassword] = useState({ password1: "", password2: "" });
    const [loading, setLoading] = useState(false);
    const [alerta, setAlerta] = useState({ mensaje: '', show: false, color: null });
    const [deshabilitarEnviar, setDeshabilitarEnviar] = useState(false);
    const [idUsuario, setIdUsuario] = useState(null);

    useEffect(() => {
        getTokenPw();
    }, []);

    async function getTokenPw() {
        setLoading(true);
        try {
            const code = window.location.pathname.split("/new-password/")[1];
            const response = await ApiComunicator("/auth/getTokenPw", "POST", { code });
            if (response.token) {
                setIdUsuario(response.idUsuario);
            } else {
                setAlerta({
                    color: "danger",
                    show: true,
                    mensaje: "El token expiró o no tiene permisos para esta solicitud."
                });
                setDeshabilitarEnviar(true);
            }
        } catch (error) {
            setAlerta({
                color: "danger",
                mensaje: "Ocurrió un error al buscar el token.",
                show: true
            });
            setDeshabilitarEnviar(true);
        } finally {
            setLoading(false);
        }
    }

    async function handleSubmit(e) {
        e.preventDefault();
        if (formPassword.password1 !== formPassword.password2) {
            setAlerta({
                mensaje: "Las contraseñas no coinciden",
                show: true,
                color: "danger"
            });
            setFormPassword({ password1: "", password2: "" });
            return;
        }

        setLoading(true);
        try {
            const token = window.location.pathname.split("/new-password/")[1];
            const response = await ApiComunicator("/auth/cambiarPassword", 'POST', {
                password: formPassword.password1,
                token,
                idUsuario
            });

            if (response.exito) {
                setAlerta({
                    mensaje: response.mensaje,
                    show: true,
                    color: "success"
                });
                setDeshabilitarEnviar(true);
                setTimeout(() => {
                    navigate("/login");
                }, 3000);
            }
        } catch (error) {
            setAlerta({
                color: "danger",
                mensaje: error.mensaje || "Ocurrió un error al cambiar la contraseña.",
                show: true
            });
        } finally {
            setLoading(false);
        }
    }

    return (
        <div id="cambiarPw">
            {loading && <Spinner />}
            <Card>
                <CardTitle id="titulo-pw" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <img className="logo-image" src={process.env.PUBLIC_URL + '/arauco.svg'} alt="arauco" />
                    <h2 style={{ textAlign: 'center' }}>{diccionario.tituloLogin}</h2>
                </CardTitle>
                <CardBody>
                    <Form onSubmit={handleSubmit}>
                        <FormGroup>
                            <div className="text-center">
                                <b>{diccionario.cambiarPassword}</b>
                            </div>
                        </FormGroup>
                        <FormGroup>
                            <Label for="password1">{diccionario.nuevaPassword}</Label>
                            <Input
                                type="password"
                                name="password1"
                                id="password1"
                                onChange={e => setFormPassword({ ...formPassword, password1: e.target.value })}
                                value={formPassword.password1}
                                required
                                minLength={8}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="password2">{diccionario.repetirNuevaPassword}</Label>
                            <Input
                                type="password"
                                name="password2"
                                id="password2"
                                onChange={e => setFormPassword({ ...formPassword, password2: e.target.value })}
                                value={formPassword.password2}
                                required
                                minLength={8}
                            />
                        </FormGroup>
                        {alerta.show && (
                            <Alert color={alerta.color} className="mt-3">
                                {alerta.mensaje}
                            </Alert>
                            
                        )}
                        <div className="d-flex flex-column align-items-center mt-5">
                            <CustomButton
                                type="submit"
                                colorFondo="orange"
                                size="md"
                                disabled={deshabilitarEnviar}
                            >
                                {diccionario.enviar}
                            </CustomButton>
                        </div>
                    </Form>
                </CardBody>
            </Card>
        </div>
    );
};

export default CambiarPassword;