import React, { useContext } from 'react';
import { Nav, NavItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import './Footer.css';
import { LanguageContext } from '../../App';

export const Footer = ( { show } ) => {
  const { diccionario } = useContext(LanguageContext);

  return (
    <Nav id="app-footer" style={{display: show ? 'flex': 'none'}}>
      <NavItem className="footer-left">
        <Link to="/" className="nav-link">
          {diccionario.sistemaBloqueosArauco || 'Sistema de Bloqueos - Arauco'}
        </Link>
      </NavItem>
      <NavItem className="footer-right">
        <a href="https://itport.com.ar" className="nav-link" target="_blank" rel="noopener noreferrer">
          ITPort
        </a>
        <span className="services-text"> - {diccionario.serviciosTecnologicos || 'Servicios Tecnológicos'}</span>
      </NavItem>
    </Nav>
  );
};