import React, { useContext, useState, useEffect, useRef } from 'react';
import { FaUser, FaSignOutAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { ApiComunicator } from '../../Helpers/ApiComunicator';
import { AuthContext, LanguageContext } from '../../App';
import { CustomButton } from '../CustomButton/CustomButton';

export const PanelUsuario = () => {
    const [dropdownUserOpen, setDropdownUserOpen] = useState(false);
    const { setAutenticado } = useContext(AuthContext);
    const { diccionario } = useContext(LanguageContext);
    const dropdownRef = useRef(null);
    const navigate = useNavigate();

    const toggleUser = () => setDropdownUserOpen(prevState => !prevState);

    const logout = async () => {
        try {
            await ApiComunicator('/auth/logout', 'GET');
            setAutenticado(false);
            navigate('/login', { replace: true });
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownUserOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const getPerfilInfo = (codigoPerfil) => {
        switch (codigoPerfil) {
            case 'P_ADMIN':
                return { nombre: 'Admin', color: '#F0F0F0' };
            case 'P_TEC':
                return { nombre: 'Técnico', color: '#F0F0F0' };
            case 'P_VIS':
                return { nombre: 'Visitante', color: '#F0F0F0' };
        }
    };

    const codigoPerfil = localStorage.getItem('codigoPerfil') || '';
    const { nombre: tipoPerfil, color: colorPerfil } = getPerfilInfo(codigoPerfil);

    return (
        <div style={{ display: "flex", alignItems: "center", gap: "10px", position: "relative" }}>
            <div
                id="boton-dropdown-usuario"
                onClick={toggleUser}
                className="dropdown-button"
            >
                <div id="logo-usuario" style={{ color: '#6c5a4f' }}>
                    <FaUser size={28} />
                </div>
            </div>
            {dropdownUserOpen && (
                <div
                    ref={dropdownRef}
                    className="dropdown-content"
                >
                    {localStorage?.getItem('apellido') && localStorage?.getItem('nombre') && (
                        <>
                            <CustomButton size="md" >
                                {localStorage.getItem('nombre')} {localStorage.getItem('apellido')}
                            </CustomButton>
                            <hr style={{ margin: "0", borderColor: "#ddd" }} />
                            <div style={{
                                padding: '8px',
                                display: 'flex',
                                justifyContent: 'start',
                                alignItems: 'center'
                            }}>
                                <span style={{
                                    backgroundColor: colorPerfil,
                                    color: '#333',
                                    padding: '4px 8px',
                                    fontSize: '0.8em',
                                    marginLeft: '8px',
                                    fontWeight: 'bold'
                                }}>
                                    Perfil: {tipoPerfil}
                                </span>
                            </div>

                        </>
                    )}
                    <hr style={{ margin: "0", borderColor: "#ddd" }} />
                    <div style={{
                        padding:'8px',
                        marginLeft: '8px',
                        display: 'flex',
                        justifyContent: 'start',
                        alignItems: 'center'
                    }}>

                        <CustomButton size="sm" colorFondo="brownishGrey" onClick={logout} icon={FaSignOutAlt}>
                            {diccionario.cerrarSesion}
                        </CustomButton>
                    </div>
                </div>
            )}
        </div>
    );
};