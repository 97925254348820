import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import './NotFound.css';
import { CustomButton } from '../CustomButton/CustomButton';
import { LanguageContext } from '../../App';

export const NotFound = () => {
    const { diccionario } = useContext(LanguageContext);
    const navigate = useNavigate();

    return (
        <div className="not-found-container">
            <h1>404</h1>
            <h2>{diccionario.paginaNoEncontrada}</h2>
            <p>{diccionario.mensajeErrorPaginaNoEncontrada}</p>
            <CustomButton size="md" colorFondo="orange" onClick={() => { navigate("/") }}>
                {diccionario.volverPaginaPrincipal}
            </CustomButton>
        </div>
    );
};