import React, { useState, useRef } from 'react';
import { Button as ReactstrapButton, Tooltip } from 'reactstrap';

const backgroundColors = {
    beige: '#e6dfd3',  
    orange: '#FF9800',  
    yellow: '#FFD600',
    brownishGray: '#6c5a4f',
    danger: '#ff5c5c' 
};

const darkenColor = (color, amount) => {
    return '#' + color.replace(/^#/, '').replace(/../g, color => ('0' + Math.min(255, Math.max(0, parseInt(color, 16) - amount)).toString(16)).substr(-2));
};

const getContrastColor = (hexColor) => {
    const r = parseInt(hexColor.slice(1, 3), 16);
    const g = parseInt(hexColor.slice(3, 5), 16);
    const b = parseInt(hexColor.slice(5, 7), 16);
    
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
    
    return luminance > 0.5 ? '#000000' : '#ffffff';
};

export const CustomButton = ({ size, colorFondo, onClick, icon: Icon, children, disabled, style, tooltipText }) => {
    const [isHovered, setIsHovered] = useState(false);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const buttonId = useRef(`Tooltip-${Math.random().toString(36).substr(2, 9)}`);

    const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

    const baseColor = colorFondo ? backgroundColors[colorFondo] || backgroundColors.brownishGray : 'transparent';
    const hoverColor = colorFondo ? darkenColor(baseColor, 20) : '#e6dfd3';
    const textColor = baseColor === 'transparent' ? '#6c5a4f' : getContrastColor(baseColor);

    const buttonStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: children ? 'flex-start' : 'center',
        color: textColor,
        backgroundColor: isHovered && !disabled ? hoverColor : baseColor,
        border: 'none',
        transition: 'background-color 0.3s ease',
        padding: children ? '0.5rem 1rem' : '0.5rem',
        fontWeight: 'bold',
        opacity: disabled ? 0.5 : 1,
        cursor: disabled ? 'not-allowed' : 'pointer',
        ...style,
    };

    const iconStyle = {
        marginRight: children ? '5px' : '0',
        display: 'block',
    };

    return (
        <>
            <ReactstrapButton 
                id={buttonId.current}
                onClick={onClick} 
                style={buttonStyle} 
                size={size}
                onMouseEnter={() => !disabled && setIsHovered(true)}
                onMouseLeave={() => !disabled && setIsHovered(false)}
                disabled={disabled}
            >
                {Icon && <Icon size={16} style={iconStyle} />}
                {children}
            </ReactstrapButton>
            {tooltipText && (
                <Tooltip
                    placement="top"
                    isOpen={tooltipOpen}
                    target={buttonId.current}
                    toggle={toggleTooltip}
                >
                    {tooltipText}
                </Tooltip>
            )}
        </>
    );
};