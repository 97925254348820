import React, { useState, useRef, useContext, useEffect } from 'react';
import { FaGlobe } from 'react-icons/fa';
import { LanguageContext } from '../../App';
import { CustomButton } from '../CustomButton/CustomButton';
import useWidth from '../../Hooks/useWidth';

export const DropdownIdioma = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { idioma, setIdioma, diccionario } = useContext(LanguageContext);
  const dropdownRef = useRef(null);

  const width = useWidth()

  const toggle = () => setDropdownOpen(prevState => !prevState);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className="dropdown-container" ref={dropdownRef}>
      <button
        id="boton-idioma"
        onClick={toggle}
        className="dropdown-button"
      >
        {width > 800 ? <span className="idioma-texto">{idioma.toUpperCase()}</span> : null}
        <FaGlobe size={28} />
      </button>
      {dropdownOpen && (
        <div className="dropdown-content" style={{ width: 120 }}>
          <CustomButton onClick={() => { setIdioma('es'); setDropdownOpen(false) }} size="sm" style={{ padding: 10 }}>
            {diccionario.espanol}
          </CustomButton>
          <CustomButton onClick={() => { setIdioma('en'); setDropdownOpen(false) }} size="sm" style={{ padding: 10, borderTop: "1px solid lightgrey" }}>
            <span>{diccionario.ingles}</span>
          </CustomButton>
        </div>
      )}
    </div>
  );
};
