import React from 'react';
import { Spinner as SpinnerBootstrap } from 'reactstrap';
import './LocalSpinner.css';

export const LocalSpinner = ({ message }) => {
    return (
        <div className="local-spinner">
            <div className="spinner-wrapper">
                <SpinnerBootstrap className="custom-spinner" />
                {message && <p>{message}</p>}
            </div>
        </div>
    );
};